.calcite-map.sidebar-revealed {
  #mapViewDiv {
    @include calc(width , "100vw - #{$sidebar-width}");
    @include transition(width .35s ease);
  }
}

.calcite-map {
  #mapViewDiv {
    width: 100%;
    @include transition(width .35s ease);
  }
}

.mobile {
  #mapViewDiv {
    width: 100% !important;
  }
}
