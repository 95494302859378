@import url('https://rsms.me/inter/inter.css');
// html {
//   font-family: 'Inter', sans-serif;
// }
@supports (font-variation-settings: normal) {
  html {
    font-family: 'Inter var', sans-serif;
  }
}

// Globals
$font-stack: 'Inter', sans-serif;;
$primary-black-color: #000000;
$dark-grey-color: #5F6368;
$primary-grey-color: #a9a9b1;
$medium-grey-color: #EFEFEF;
$light-grey-color: #F8F9FA;
$accent-color: #0056D6;
$navbar-height: 50px;
$sidebar-width: 500px;
$skinny-shadow: 0 1px 2px 0 rgba(60,64,67,0.3), 0 2px 6px 2px rgba(60,64,67,0.15);
$fat-shadow: 0 1px 70px rgba(0, 0, 0, 0.15);
$component-border: 1px solid rgba(50,50,50,0.25);

// Mixins
@mixin transition($args...) {
  -webkit-transition: $args;
  -moz-transition: $args;
  -ms-transition: $args;
  -o-transition: $args;
  transition: $args;
}

@mixin calc($property, $expression) {
  #{$property}: -webkit-calc(#{$expression});
  #{$property}: calc(#{$expression});
}

.view {
  width: 100%;
  margin-top: $navbar-height;
  background-color: $light-grey-color;
}