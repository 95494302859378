.esri-popup__content {

  p {
    font-size: 14px;
    max-width: 400px;
  }

  a[href]{
    font-size: 14px;
    color: $accent-color;
    text-decoration: none;
    cursor: pointer;
    &:hover {
      color: $primary-black-color;
      text-decoration: none;
    }
  }

}

.esri-popup {
  font-family: $font-stack;

  .esri-popup__header-title {
    font-size: 14px;
    font-weight: 300;
    text-transform: uppercase;
    color: $primary-black-color;
    strong {
      font-size: 12px;
      font-weight: 600;
      padding-bottom: 3px;
      display: block;
    }
  }

  .esri-popup__main-container {
    max-height: 370px;
    background-color: $light-grey-color;
    width: auto;
  }

  .streams-popup-content-container {
    width: 550px;
  }

}

.popup-footer {
  display: flex;
  margin-top: 10px;

  .popup-actions {
    display: inherit;
    position: relative;
    width: 100%;
    align-items: center;
    justify-content: center;

    .chart-controls {
      .arrows {
        display: flex;
        div[class^="arrow-"] {
          font-size: 12px;
          padding: 0px 10px;
          &:hover {
            cursor: pointer;
          }
        }
        .disabled {
          color: $primary-grey-color;
          cursor: default;
        }
      }

      .chart-name {
        font-weight: 600;
        font-size: 12px;
        width: 140px;
        text-align: center;
      }

      .dots {
        display: flex;
        justify-content: center;
        .dot {
          padding: 0px 4px;
          &.available {
            display: inline-flex;
            color: #D8D8D8;
          }
          &.active {
            color: $accent-color;
          }
          &:hover {
            cursor: pointer;
          }
        }
      }
    }

    .queue-controls {
      display: inherit;
      position: absolute;
      right: 0;

      .action-button {
        padding: 4px 6px 3px 7px;

        .button-text {
          max-width: 0;
          margin: 0px;
          @include transition(max-width .25s ease);
          display: inline-block;
          white-space: nowrap;
          overflow: hidden;
        }

        &:hover .button-text {
          max-width: 125px;
          margin-left: 5px;
        }

        &[data-action="add-stream-to-queue"] {
          .icon.esri-icon-plus {
            display: block;
          }
          .icon.esri-icon-minus {
            display: none;
          }
        }

        &[data-action="remove-stream-from-queue"] {
          .icon.esri-icon-plus {
            display: none;
          }
          .icon.esri-icon-minus {
            display: block;
          }
        }
      }
    }

    .chart-type-tabs {
      display: inherit;

      .action-button {
        padding-left: 20px;
        font-weight: bold;
        color: $primary-grey-color;
        cursor: pointer;

        .button-text {
          padding-right: 2px;
          padding-top: 6px;
          font-size: 12px;
        }

        .active-underline {
          display: block;
          width: 25px;
          height: 2px;
          vertical-align: 11px;
          margin: 3px auto 0px auto;
          pointer-events: none;
          background-color: #85C1E9;
          opacity: 0;
        }

        &.active {
          color: $primary-black-color;
          .active-underline {
            opacity: 1;
          }
        }

        &:hover {
          color: $primary-black-color;
        }

      }

    }

  }

}

.mobile,
.esri-popup--is-docked {

  .queue-controls {
    display: none !important;
  }

  .streams-popup-content-container {
    width: 100%;
    max-height: 100%;
  }

  .esri-popup .esri-popup__main-container {
    width: 100%;
    max-height: 100%;
  }
  
}



// @media screen and (orientation:landscape) {
// 	.esri-popup.esri-popup--is-docked {
//     margin: 0px;
//   }
// }
