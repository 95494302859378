.navbar {
  background-color: #FFFFFF;
  box-shadow: $skinny-shadow;
  border-bottom: 1px solid $light-grey-color;
  width: 100%;
  @include transition(width .35s ease);

  &.map-view {
    border-bottom: $component-border;
  }

  .natural-flows-logo,
  .tnc-logo,
  .nav-link {
    cursor: pointer;
  }

  .tnc-logo {
    margin: 0 20px;
    .full-logo {
      display: block;
      width: 110px;
      height: 80%;
    }
    .mobile-logo {
      display: none;
    }
  }

  .natural-flows-logo {
    max-width: 175px;
  }

  .calcite-title-main {
    color: $primary-black-color;
    font-size: 20px;
    line-height: normal;
  }

  .nav-icon {
    font-size: 11px;
    line-height: 1;
    margin-left: 11px;
    border-radius: 3px;
    padding: 1px 4px;

    &.dev-mode {
      display: none;
      color: #5454C5;
      border: 1px solid #5454C5;
      // margin-left: 7px;
    }
  }

  [class*="esri-icon-"] {
    font-weight: bold !important;
  }

  .navbar-nav {
    padding-right: 5px;
    li>a {
      padding: 15px 20px;
    }
  }

  .dropdown.calcite-dropdown {
    display: none;
    order: 3;

    .dropdown-toggle {
      background-image: none;
      &::after {
        display: none;
      }
    }
  }

  .nav-link {
    font-size: 16px !important;
    font-weight: 500;
    color: $primary-black-color !important;

    &:hover,
    &:focus {
      background-image: none !important;
      background-color: rgba(32, 33, 36, 0.04) !important;
    }

    &.active {
      background-image: linear-gradient(to bottom,transparent 95%,$accent-color 3px) !important;
    }

  }

}

.mobile {

  .tnc-logo .full-logo {
    display: none;
  }
  .tnc-logo .mobile-logo {
    display: block;
  }

  .calcite-nav.nav.navbar-nav {
    display: none;
  }
  .dropdown.calcite-dropdown {
    display: block;
    position: initial;
    .dropdown-menu {
      background-color: #FFFFFF;
      display: flex;
      justify-content: space-around;
      width: 100%;
      height: 40px;
      left: 0;
    }

    .calcite-dropdown-toggle span {
      background-color: $primary-black-color;
    }
  }
}