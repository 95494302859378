.widget-button {
  padding: 8px;
  font-size: 14px;
  color: $dark-grey-color;
  background-color: #FFFFFF;

  &:hover {
    cursor: pointer;
    color: $primary-black-color;
    background-color: $light-grey-color;
  }
}

.legend-expander {
  position: relative;
}

.legend-header {
  height: 30px;
  background-color: $light-grey-color;
  position: absolute;
  right: -1px;
  top: -1px;

  .widget-button {
    // border: 1px solid rgba(0,0,0,0);
    border-top: $component-border;
    border-right: $component-border;

    &[data-toggle="open"] {
      border: $component-border;
    }

  }
}

#locate {
  display: none;
  width: 32px;
  height: 32px;
  .esri-icon {
    padding-top: 1px;
    padding-left: 1px;
  }
}

#search {
  .esri-search__sources-button {
    display: flex;
  }
  .esri-search__submit-button {
    border: none;
  }
}

.hint {
  box-shadow: none !important;
  border: none !important;
  margin-right: 0px !important;
  margin-bottom: 5px;
  background: #FFFFFF;
  border-radius: 3px;
  padding: 10px;
  display: flex;
  align-items: center;

  .widget-button {
    font-size: 12px;
  }

  .text {
    margin-left: 5px;
  }
}

.mobile {
  #collapseSidebar {
    display: none;
  }
  #locate {
    display: block;
  }
}
