span.internal-link,
a {
  color: $accent-color;
  text-decoration: none;
  cursor: pointer;
  overflow-wrap: break-word;
  &:hover {
    color: $primary-black-color;
    text-decoration: none;
  }
}

.header-section,
.body-section,
.footer-section {
  padding: 60px 0px;

  .container {
    padding-right: 30px;
    padding-left: 30px;
  }
}

.header-section {
  position: relative;
  z-index: 10;
  .header-content {

    h1 {
      font-size: 48px;
      font-weight: 200;
      margin-bottom: 25px;
    }

    p {
      font-size: 18px;
      margin-bottom: 25px;
      line-height: 26px
    }

    button {
      background-color: $accent-color;
      border-color: $accent-color;
      color: #FFFFFF;
      &:hover {
        color: $accent-color;
        background-color: #FFFFFF;
      }
    }

  }
}

.body-section {
  background-color: #FFFFFF;

  .section {
    margin-bottom: 50px;
  }

  h2 {
    font-weight: 500;
    margin-bottom: 30px;
  }

  h3 {
    margin-bottom: 20px;
  }

  p, ul {
    font-size: 16px;
    color: $dark-grey-color;
    margin-bottom: 25px;
    line-height: 24px;
  }

  .teaser {
    padding-left: 0px;
    padding-right: 20px;
    padding-bottom: 30px;

    .thumbnail img {
      max-width: 100%;
      padding-bottom: 16px;
    }
  }
}

.footer-section {
  background-color: $light-grey-color;
  padding-bottom: 0px;

  p {
    color: $primary-black-color;
  }

  .row {
    margin-bottom: 40px;
  }

  .rec-citation {
    text-align: center;
    h3 {
      margin-bottom: 20px;
    }
    p {
      font-size: 12px;
      color: $dark-grey-color;
    }
  }

  .partner-logos {
    align-items: center;
    a {
      padding: 0px 30px;
      display: inherit;
      vertical-align: middle;

      svg {
        max-width: 140px;
        path,
        polygon,
        polyline {
          fill: $primary-black-color;
        }
      }

      &:hover {
        svg {
          path,
          polygon,
          polyline {
            fill: $dark-grey-color;
          }
        }
      }
    } 

  }

  .copyright {
    margin-top: 25px;
    font-size: 12px;

    span.internal-link,
    a {
      color: $primary-black-color;
      &:hover {
        color: $accent-color;
      }
    }
  }

}

a.button-link {
  text-decoration: none;
  border: 2px solid $accent-color;
  color: $accent-color;
  background-color: #ffffff;
  border-radius: 3px;
  font-size: 18px;
  font-weight: 300;
  padding: 7px 16px 5px 16px;

  &.disabled {
    border-color: $primary-grey-color;
    color: $primary-grey-color;
  }

  &:hover:not([disabled]) {
    color: #FFFFFF;
    background-color: $accent-color;
  }
}

button {
  border: 2px solid $accent-color;
  color: $accent-color;
  background-color: #ffffff;
  border-radius: 3px;
  font-size: 18px;
  font-weight: 300;
  padding: 7px 16px 5px 16px;

  &.disabled {
    border-color: $primary-grey-color;
    color: $primary-grey-color;
  }

  &:hover:not([disabled]) {
    color: #FFFFFF;
    background-color: $accent-color;
  }
}

button.pill {
  border: 1px solid $primary-black-color;
  background: none;
  font-size: 12px;
  color: $primary-black-color;
  font-weight: bold;
  padding: 4px 10px 4px 14px;
  border-radius: 20px;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  .icon {
    font-size: 10px !important;
  }

  .button-text {
    margin: 0px 5px;
  }

  // &.disabled {
  //   border-color: $primary-grey-color;
  //   color: $primary-grey-color;
  // }

  &:hover:not([disabled]) {
    background-color: $primary-black-color;
    color: $light-grey-color;
    .button-text, .icon {
      color: $light-grey-color;
    }
  }
}

.ffm-table-header {
  font-size: 18px;
  font-weight: 500;
  padding-bottom: 10px;
  border-bottom: 1px solid $primary-grey-color;

  .component-name {
    padding-bottom: 10px;
    border-bottom: 1px solid $primary-black-color;
  }

}

.ffm-component-desc {
  padding-top: 12px;
  font-size: 16px;
  color:  $dark-grey-color;
  font-weight: 400;
}
.ffm-definitions-table {
  width: 100%;
  font-size: 16px;
  border-collapse: collapse;
  margin: 20px 0px 50px 0px;
  border-radius: 5px 5px 0 0;
  overflow: hidden;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);

  .header-characteristic {
    width: 18%;
  }
  .header-metric {
    width: 16%;
  }
  .header-metric-code {
    width: 16%;
  }

  thead tr {
    background-color: $primary-black-color;
    color: #ffffff;
    text-align: left;
    th {
      font-weight: 500;
    }
  }
  
  th,
  td {
    padding: 12px 15px;
  }

  tbody {
    tr:not(:last-child) {
      border-bottom: 1px solid #dddddd;
    }
    th {
      font-weight: 400;
    }
  }
  
}

.faq-view {

  .section {
    align-items: center;
    margin-bottom: 60px;
  }

  .visual {
    video, img {
      max-width: 100%;
      box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
    }
  }
}


.mobile {
  .header-section {
    padding: 20px 0px;
    .header-content {
  
      h1 {
        font-size: 40px;
      }
  
    }
  }

  .ffm-definitions-table {

    td:nth-child(1),
    th:nth-child(1) {
      display: none;
    }

  }
}