.map-sidebar {
  width: $sidebar-width;
  @include calc(height , "100vh - #{$navbar-height}");
  right: -$sidebar-width;
  min-height: 85vh;
  overflow-x: hidden;
  position: absolute;
  // top: $navbar-height;
  background-color: #FFFFFF;
  border-left: $component-border;
  @include transition(right .35s ease);
  box-shadow: $fat-shadow;

  .panel-body {
    padding: 0px 20px 20px 20px;
    position: relative;
    @include calc(height , "100vh - 50px - #{$navbar-height}");
  }

  &.revealed {
    right: 0;
  }

  .selection-mode-tabs {
    display: flex;

    .tab {
      width: $sidebar-width / 2;
      padding: 15px;
      text-align: center;
      font-size: 14px;
      cursor: pointer;
      height: 50px;

      &:not(.selected) {
        background-color: #EFEFEF;
        color: $primary-grey-color;

        &:hover {
          background-color: $light-grey-color;
          color: $accent-color;
        }
      }
    }
  }

  // Selected Streams list
  #streamQueue {

    margin-top: 20px;
    display: none;

    &.revealed {
      display: block;
    }

    .form-section-label {
      font-size: 18px;
      font-weight: 600;
      margin-top: 10px;
      display: inline;
    }

    #clearQueue {
      float: right;
    }

    #listContainer {

      margin-top: 12px;
      margin-bottom: 12px;
      border: 1px solid $primary-black-color;
      height: 180px;
      overflow-y: scroll;
      position: relative;
      display: flex;
      align-items: center;

      ul {
        list-style-type: none;
        padding: 0px;
        top: 0;
        background-color: $light-grey-color;
        width: 100%;
        position: absolute;
        z-index: 5;

        li {
          list-style-type: none;
          border: 1px solid;
          border-left: 4px solid $primary-grey-color;
          border-right: 0px;
          padding: 6px 10px;
          margin-bottom: -1px;
          position: relative;

          &:nth-child(1) {
            margin-top: -1px;
          }

          .comid {
            font-size: 16px;
            font-weight: 700;
            display: inline;
          }

          .segment-name {
            font-size: 14px;
            color: $dark-grey-color;
            font-weight: 300;
            font-style: italic;
            display: inline;
            position: absolute;
            top: 8px;
            margin-left: 16px;

          }

          .esri-icon-close {
            padding-top: 3px;
            padding-left: 10px;
            color: $dark-grey-color;
            float: right;
            cursor: pointer;

            &:hover {
              color: $primary-black-color;
            }
          }

          &:hover {
            border-left-color: $accent-color;
            background-color: #fff;
          }

        }
      }

    }
  }

  #huSelector {
    display: none;
    width: 370px;
    margin: 20px auto;

    &.revealed {
      display: flex;
    }

    .button-container {
      width: 100%;
      display: flex;
      justify-content: center;
    }

    button {
      position: relative;
      font-size: 16px;
      color: $primary-grey-color;
      border: none;
      padding: 0px;
      padding-bottom: 6px;
      border-radius: 0px;
      background: none;

      &.selected,
      &:hover {
        color: $primary-black-color;
      }

    }

    .selected:before {
      content : "";
      position: absolute;
      left: 20%;
      bottom: 0;
      height: 1px;
      width: 60%;
      border-bottom: 1px solid $primary-black-color;
    }
  }

  // HU Info
  #huInfo {
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    width: 330px;
    display: none;

    &.revealed {
      display: block;
    }

    #huLabel {
      color: $primary-grey-color;
      font-weight: 100;
      margin-top: 40px;
      margin-bottom: 0px;
    }

    #huName {
      font-size: 30px;
      font-weight: 500;
      font-family: $font-stack;
      margin-top: 6px;
      margin-bottom: 0px;
    }

    #huData {
      font-size: 16px;
      list-style-type: none;
      margin-left: 0px;
      margin-top: 15px;
      margin-bottom: 45px;
      padding: 0;
      font-weight: 300;
      color: $dark-grey-color;

      .label {
        font-weight: 500;
        color: $primary-black-color;
      }
    }
  }

  .selection-instructions {
    color: $primary-grey-color;
    font-style: italic;
    font-size: 16px;
    text-align: center;
    width: 250px;
    margin-right: auto;
    margin-left: auto;
    margin-bottom: 0px;
    opacity: 0.6;

    &.hidden {
      opacity: 0;
    }
  }

  #huSelectionInstructions {
    margin-top: 6px;
  }

  // Form
  .form-section {
    border-top: 1px solid $primary-black-color;
    display: flex;
    align-items: center;

    .form-section-label {
      font-size: 18px;
      font-weight: 600;
      margin-top: 12px;
      margin-bottom: 12px;
      width: 120px;
    }

    .options label {
      font-size: 14px;
      font-weight: 400;
      color: $primary-black-color;
      margin-bottom: 0px;
    }
    

    #statisticsCheckboxes,
    #variablesCheckboxes {
      flex-grow: 1;
      display: flex;
      justify-content: space-between;
    }

    #yearsInputs {

      .year-picker {
        display: inline;

        &:nth-child(2) {
          padding-left: 20px;
        }
        input {
          border: none;
          color: $accent-color;
          background-color: $light-grey-color;
          border-radius: 2px;
          font-size: 14px;
          padding: 2px 5px;
          width: 60px;
        }
      }

      label {
        padding-right: 10px;
      }
    }

    #monthsCheckboxes {
      height: 120px;
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
    }
  }

  #buttons {
    border: none;
    text-align: center;
    min-height: 65px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;

    .download-button {
      font-size: 14px;
      font-weight: 400;
      padding: 6px 12px 6px 22px;
      border-left-width: 1px;
      border-top-width: 1px;
      border-right-width: 1px;
      border-bottom-width: 1px;
      display: flex;
      align-items: center;
      .icon {
        font-size: 14px !important;
      }
      .button-text {
        margin: 0px 10px;
      }
    }
  }

}

// Checkboxes
.checkbox-container {
  font-weight: 400;
  display: block;
  position: relative;
  padding-left: 25px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  /* Hide the browser's default checkbox */
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }

  /* Create a custom checkbox */
  .checkmark {
    position: absolute;
    top: 2px;
    left: 0;
    height: 14px;
    width: 14px;
    border-radius: 2px;
    border: 1px solid $dark-grey-color;

    /* Create the checkmark/indicator (hidden when not checked) */
    &:after {
      content: "";
      position: absolute;
      display: none;
    }
  }

  /* On mouse-over, add a grey background color */
  &:hover input ~ .checkmark {
    background-color: $light-grey-color;
  }

  /* When the checkbox is checked, add a accent color */
  input:checked ~ .checkmark {
    // background-color: $accent-color;
    // border-color: $accent-color;
  }

  /* Show the checkmark when checked */
  input:checked ~ .checkmark:after {
    display: block;
  }

  /* Style the checkmark/indicator */
  .checkmark:after {
    left: 4px;
    width: 5px;
    height: 10px;
    border: solid $accent-color;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }

}

.mobile {
  .map-sidebar {
    display: none;
    right: -$sidebar-width !important;
  }
}
