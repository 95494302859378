$estimated-dry-color: #85C1E9;
$estimated-mod-color: #2E86C1;
$estimated-wet-color: #1B4F72;
$observed-all-color: #A3288E;
$inflated-color: #2E86C1;
$deflated-color: #DA2A1D; // #E14343; //#CE0A0A;

div[class*="-chart"] {
  display: none;

  &.active {
    display: block;
  }
}

.chart-type-tabs {
  div[class*="-chart"].available {
    display: block;
  }
}


/* focused metric displays */

.focus-metrics,
.metric {
  display: block;
}

.focus-metrics {
  width: 100%;
  display: flex;
  justify-content: center;

  .metric-display {
    min-width: 400px;
    display: flex;
    justify-content: space-around;
  }

  .variable {

    .variable-label {
      font-size: 12px;
      font-weight: bold;
      font-style: italic;
      text-align: center;
      padding-bottom: 3px;
      border-bottom: 1px solid $primary-black-color;
    }

    .metrics {
      display: flex;
    }

    .metric {
      width: 82px;
      height: 40px;
      margin-right: 11px;

      .dot {
        width: 10px;
        height: 10px;
        border-radius: 5px;
        display: inline-block;
        margin-right: 5px;
      }

      .label {
        padding-top: 5px;
        padding-bottom: 3px;
        font-size: 12px;
        font-weight: 500;
      }

      .focus-data {
        font-size: 16px;
        padding-top: 5px;
        padding-left: 14px;
      }

      .units {
        font-size: 10px;
        padding-left: 3px;
      }

      &.raster {
        width: 100%;

        .label {
          display: flex;
        }
        .dot {
          margin-top: 3px;
          background-color: $medium-grey-color;
        }
      }

      &:last-child {
        margin-right: 0px;
      }
    }

  }

  .observed {
    display: none;

    .metric {
      width: 92px;
    }

    .all .dot {
      background-color: $observed-all-color;
    }

    .observation-count {
      display: none;
      font-size: 10px;
      padding-left: 14px;
      .count {
        border: 1px solid $primary-black-color;
        color: $observed-all-color;
        border-radius: 2px;
        padding: 0px 2px;
      }
    }
  }

  .estimated {
    .dry .dot {
      background-color: $estimated-dry-color;
    }
    .mod .dot {
      background-color: $estimated-mod-color;
    }
    .wet .dot {
      background-color: $estimated-wet-color;
    }
  }

  .flow-alteration {
    .metric {
      width: 92px;
    }
    .inflated .dot {
      background-color: $inflated-color;
    }
    .deflated .dot {
      background-color: $deflated-color;
    }
  }

}

/* Charts */

.spinner {
  border: 2px solid #f3f3f3;
  border-radius: 50%;
  border-top: 2px solid $primary-black-color;
  width: 60px;
  height: 60px;
  -webkit-animation: spin 1.5s ease-in-out infinite; /* Safari */
  animation: spin 1.5s ease-in-out infinite;
  position: absolute;
  left: calc(50% - 30px);
  top: calc(50% - 30px);
}

/* Safari */
@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.chart {

  min-height: 200px;

  /* Axis */
  .domain,
  .tick line {
    fill: none;
    stroke: $primary-grey-color;
    stroke-width: 0.5;
    opacity: 0.5;
  }

  text {
    fill: $primary-grey-color;
    font: 12px $font-stack;
    &.focused {
      fill: $primary-black-color;
    }
  }

  /* overlay */
  .overlay {
    fill: none;
    pointer-events: all;
  }

}

/* Line hydrograph */
#lineHydrograph {
  
  .metric {
    .line {
      fill: none;
      stroke-width: 2;
    }
    .point {
      stroke-width: 2;
      stroke: #ffffff;
    }
    .range {
      fill-opacity: 0.20;
      fill: $primary-grey-color;
    }
    &.observedAll {
      .line { stroke: $observed-all-color; }
      .point { fill: $observed-all-color; }
    }
    &.estimatedDry {
      .line { stroke: $estimated-dry-color; }
      .point { fill: $estimated-dry-color; }
    }
    &.estimatedMod {
      .line { stroke: $estimated-mod-color; }
      .point { fill: $estimated-mod-color; }
    }
    &.estimatedWet {
      .line { stroke: $estimated-wet-color; }
      .point { fill: $estimated-wet-color; }
    }
  }

  .focus {
    circle {
      stroke-width: 2;
      stroke-opacity: 1;
      fill: #ffffff;
    }
    .halo {
      stroke-width: 0;
      stroke-opacity: 0.3;
      fill-opacity: 0;
    }
    .observedAll {
      stroke: $observed-all-color;
    }
    .estimatedDry {
      stroke: $estimated-dry-color;
    }
    .estimatedMod {
     stroke: $estimated-mod-color;
    }
    .estimatedWet {
      stroke: $estimated-wet-color;
    }
  }

  .observed-tooltip {
    display: none;
    rect {
      stroke: $primary-black-color;
      stroke-width: 1;
      fill: $light-grey-color;
      rx: 2;
    }
    .triangle {
      fill: $light-grey-color;
      stroke: none;
    }
    .triangle-outline {
      stroke: $primary-black-color;
      fill: none;
    }
    text {
      font-size: 12px;
      font-weight: bold;
      fill: $primary-black-color;
      &.count {
        fill: $observed-all-color;
      }
    }
  }

  .confidence-interval-key {
    rect {
      fill-opacity: 0.20;
      fill: $primary-grey-color;
    }
    .label {
      fill: $primary-black-color;
      font-size: 12px;
    }
  }

}

/* raster chart */
#rasterHydrograph {

  .domain,
  .tick line {
    display: none;
  }

  .month-labels text:nth-child(even) {
    display: none;
  }

  rect:hover {
    fill: #21618C;
  }

}

/* flow alteration chart */
#flowAlteration {

  .inflated-bars {
    fill: $inflated-color;
  }

  .deflated-bars {
    fill: $deflated-color;
  }

  .focus-line {
    stroke-width: 1;
    stroke: $primary-black-color;
  }
}

.functional-flow-metrics-chart {
  .ffm-filters {
    display: flex;
    justify-content: space-evenly;

    .filter {
      position: relative;
      padding-bottom: 5px;
    }

    .filter-label {
      font-size: 12px;
      font-weight: bold;
      font-style: italic;
      text-align: center;
      padding-bottom: 5px;
    }

    .filter-selector {
      min-width: 122px;
      border: 1px solid #0056D6;
      background-color: #FFFFFF;
      border-radius: 3px;
      text-align: center;
      font-size: 12px;
      // text-transform: uppercase;
      padding: 4px;
      cursor: pointer;
      .text {
        margin-right: 5px;
        font-weight: 500;
      }
      .icon {
        transform: rotate(90deg);
        font-size: 10px;
      }
      &:hover {
        background-color: $light-grey-color;
      }
    }

    .filter-options {
      position: absolute;
      min-width: 122px;
      left: 50%;
      -webkit-transform: translateX(-50%);
      transform: translateX(-50%);
      display: none;
      margin-top: 5px;
      padding: 6px 10px;
      background-color: #FFFFFF;
      border-radius: 4px;
      border: 1px $primary-grey-color solid;
      -webkit-box-shadow: 0px 0px 8px 1px rgba(0,0,0,0.15);
      -moz-box-shadow: 0px 0px 8px 1px rgba(0,0,0,0.15);
      box-shadow: 0px 0px 8px 1px rgba(0,0,0,0.15);
      z-index: 1;
      .checkbox-container {
        font-size: 14px;
      }
      &.show {
        display: block;
      }
    }

    .filter.disabled {
      .filter-label {
        color: $primary-grey-color;
      }
      .filter-selector {
        border-color: $primary-grey-color;
        color: $primary-grey-color;
        cursor: default;
        &:hover {
          background-color: #FFFFFF;
        }
      }
    }

    .component .filter-options {
      width: 200px;
    }

  }

  .ffm-table {
    margin-top: 15px;
    width: 100%;
    border-collapse: collapse;
    table-layout: fixed;

    thead {
      font-size: 11px;
      font-weight: 500;
      color: $dark-grey-color;
      th {
        padding-bottom: 10px;
        .mobile-text {
          display: none;
        }
      }
    }
    
    .first-cell {
      text-transform: uppercase;
      width: 40%;
    }

    tbody {
      tr {
        font-size: 12px;
        border-top: 1px solid #d6d6d6;
      }
      th {
        font-weight: 600;
      }
      td {
        padding-top: 11px;
        padding-bottom: 11px;
        .val {
          font-weight: 500;
          padding-right: 6px;
        }
        .unit {
          color: $primary-grey-color;
          font-size: 10px;
          text-transform: uppercase;
        }
      }

      td.indeterminate .val::after {
        content: '*';
      }

    }
  }

  [data-tt-type] {
    text-decoration: underline;
    &:hover {
      color: $accent-color;
      cursor: pointer;
    }
  }
}

.ffm-tooltip {
  position: absolute;
  left: 15px;
  padding: 10px 15px;
  font-size: 12px;
  background-color: #ffffff;
  border: 1px solid #000000;
  border-radius: 5px;
  width: calc(100% - 30px);
  display: flex;
  align-items: center;
  img {
    width: 12px;
    padding-right: 15px;
  }
  .header {
    font-weight: 600;
  }
  .body {
    padding-left: 10px;
    color: $dark-grey-color;
    .dynamic {
      font-weight: 600;
      color: #000000;
    }
  }

  .arrow {
    width: 0;
    height: 0;
    position: absolute;
  }
  .arrow.top {
    top: -7px;
    .fill {
      position: absolute;
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
      border-bottom: 7px solid #ffffff;
    }
    .outline {
      position: absolute;
      top: -1px;
      left: -1px;
      border-left: 9px solid transparent;
      border-right: 9px solid transparent;
      border-bottom: 8px solid #000000;
    }
  }

  .arrow.bottom {
    bottom: 0;
    .fill {
      position: absolute;
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
      border-top: 7px solid #ffffff;
    }
    .outline {
      position: absolute;
      left: -1px;
      border-left: 9px solid transparent;
      border-right: 9px solid transparent;
      border-top: 8px solid #000000;
    }
  }

}

.no_data,
.not_enough_observed_data,
.no_prediction_data {
  color: $primary-grey-color !important; /* TODO: this is ugly. clean this up */
  .val {
    color: $primary-grey-color;
  }
}

.likely_altered_early,
.likely_altered_high,
.likely_altered_late,
.likely_altered_low {
  color: #CF4F5D !important;
  .val {
    // color: #DA2A1D;
    color: #CF4F5D;
  }
}

.indeterminate,
.likely_unaltered {
  color: #15A47E !important;
  .val {
    // color: #2E86C1;
    color: #15A47E;
  }
}

.mobile {

  .focus-metrics {
    padding-bottom: 10px;
    display: block;
    .metric-display {
      display: flex;
      justify-content: space-around;
      min-width: 0;
    }
    .variable {
      padding-left: 0px;
      .metric {
        .label {
          font-size: 10px;
        }
        .focus-data {
          font-size: 13px;
        }
      }
    }
    .line-hydrograph .metric {
      width: 73px;
    }
  }

  .ffm-filters {
    display: block;

    .filter-label {
      display: none;
    }

    .filter-options {
      width: 100% !important;
    }
  }
  
  .ffm-table {
    thead th .mobile-text {
      display: block;
    }
    thead th .desktop-text {
      display: none;
    }
    th:nth-child(3),
    td:nth-child(3) {
      display: none;
    }
    tbody td .val {
      padding-right: 2px;
    }
  }

  .popup-footer .chart-type-tabs {
    width: 100%;
    justify-content: space-around;
    .action-button {
      padding-left: 0px;
    }
  }
}