html,
body,
#mapArea {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
}

body {
  font: 100% $font-stack;
  color: $primary-black-color;
  background-color: $medium-grey-color;
}

a:focus,
button:focus,
input:focus,
textarea:focus,
li:focus,
h2:focus,
div:focus,
.esri-view .esri-view-surface--inset-outline:focus::after {
  outline: none;
}

.esri-component:not(.esri-attribution):not(.esri-spinner):not(.esri-popup--is-docked) {
  font-family: $font-stack !important;
  box-shadow: $fat-shadow !important;
  border: $component-border;
  color: $primary-black-color !important;

  h2, p {
    font-family: $font-stack !important;
  }

  .esri-icon {
    color: $primary-black-color !important;
  }

  .esri-widget__heading {
    color: $primary-black-color;
  }

  .esri-widget--button {
    color: $primary-grey-color;

    &:hover {
      color: $primary-black-color;
    }
  }

}

.esri-widget {
  font-family: $font-stack !important;
  color: $primary-black-color !important;
  background-color: $light-grey-color;

  .esri-widget--button,
  .esri-input {
    background-color: $light-grey-color;
  }
}

[class*="esri-icon-"] {
  font-weight: bold !important;
}

.calcite-panels {
  background-color: $light-grey-color !important;
  color: $primary-black-color !important;

  .panel-heading {
    background-color: #EFEFEF !important;
  }
}

.navbar-nav {
  flex-direction: inherit;
}

.esri-attribution {
  background-color: rgba(0,0,0,0);
}

.esri-popup {
  z-index: 0 !important;
}

.mobile .esri-popup {
  z-index: 10 !important;
}

.esri-popup__pointer-direction {
  background-color: $light-grey-color !important;
}

.esri-popup__header-title:hover,
.esri-popup__button:hover {
  background: none;
}